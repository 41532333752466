import store from '@/store'

const accountKey = 'user-account';
import cookie from '@/utils/cookie'

export default {
    host(url) {
        return process.env.VUE_APP_PROTOKOL + '://' + store.getters.account + '.' + process.env.VUE_APP_ACCOUNT_DOMAIN + url
    },
    get() {
        return cookie.getCookie(accountKey)
    },
    set(token) {
        cookie.setCookie(accountKey, token, { secure: false, 'max-age': 28800 })
    },
    unset() {
        cookie.deleteCookie(accountKey)
    },
    // Дата активации лицензионного договора
    getLicenseAgreementDate() {
        return new Date('2025-01-01T00:00:00');
    },
    // Включить лицензионный договор?
    isLicenseAgreementShow() {
        const dateActivate = this.getLicenseAgreementDate();
        const dateCurrent = new Date();
        return dateCurrent >= dateActivate;
    },
    isLicenseAgreementShowForDate(datetime) {
        const dateActivate = this.getLicenseAgreementDate();
        const dateCurrent = new Date(datetime * 1000);
        return dateCurrent >= dateActivate;
    }
};
